import { useState, useEffect } from "react";
import { create } from "ipfs-http-client";
import { Buffer } from "buffer";
import { ethers } from "ethers";
import axios from "axios";

// Components
import Spinner from "react-bootstrap/Spinner";
import Navigation from "./components/Navigation";

// ABIs
import NFT from "./abis/NFT.json";

// Config
import config from "./config.json";

function App() {
  const [provider, setProvider] = useState(null);
  const [account, setAccount] = useState(null);
  const [nft, setNFT] = useState(null);
  const [ipfsClient, setIpfsClient] = useState(null);
  const [imageData, setImageData] = useState(null);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [url, setURL] = useState(null);
  const [collectionUrl, setCollectionUrl] = useState(null);

  const [message, setMessage] = useState("");
  const [isWaiting, setIsWaiting] = useState(false);

  const loadBlockchainData = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    setProvider(provider);

    const projectId = process.env.REACT_APP_IPFS_INFURA_KEY_ID;
    const projectSecret = process.env.REACT_APP_IPFS_INFURA_KEY_SECRET;
    const auth =
      "Basic " +
      Buffer.from(projectId + ":" + projectSecret).toString("base64");
    const ipfsClient = create({
      host: "ipfs.infura.io",
      port: 5001,
      protocol: "https",
      apiPath: "/api/v0",
      headers: {
        authorization: auth,
      },
    });
    setIpfsClient(ipfsClient);
    const network = await provider.getNetwork();
    const nft = new ethers.Contract(
      config[network.chainId].nft.address,
      NFT,
      provider
    );
    setNFT(nft);
    setCollectionUrl(process.env.REACT_APP_OPEN_SEA_URL)
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (imageData === "") {
      window.alert("Please create Image before minting");
      return;
    }

    setIsWaiting(true);
    // Upload image to IPFS (NFT.Storage)
    const fileHash = await uploadImage(imageData);

    // Upload image to IPFS (NFT.Storage) and Mint NFT
    await mintImage(fileHash);
  };

  const createImage = async () => {
    try {
      if (name === "" || description === "") {
        window.alert("Please provide a name and description");
        return;
      }

      setMessage("Generating Image...");
      setIsWaiting(true);
      // You can replace this with different model API's
      const URL = `https://api-inference.huggingface.co/models/stabilityai/stable-diffusion-2`;

      // Send the request
      const response = await axios({
        url: URL,
        method: "POST",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_HUGGING_FACE_API_KEY}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          inputs: description,
          options: { wait_for_model: true },
        }),
        responseType: "arraybuffer",
      });

      const type = response.headers["content-type"];
      const data = response.data;

      const base64data = Buffer.from(data).toString("base64");
      const img = `data:${type};base64,` + base64data; // <-- This is so we can render it on the page
      setIsWaiting(false);
      setImage(img);
      setImageData(data);
      setMessage("Click Mint Button to mint image as an NFT");
    } catch (err) {
      setIsWaiting(false);
      setMessage("Error while creating image with the AI model..");
    }
  };

  const uploadImage = async (imageData) => {
    try {
      setMessage("Uploading Image...");
      const addedFile = await ipfsClient.add(imageData);
      const url = `${process.env.REACT_APP_IPFS_GATEWAY}${addedFile.path}`;
      const metadata = {
        name: "AI Generated NFT",
        description: "Words to Image Ai generated NFTs",
        image: url,
        attributes: [{ trait_type: "name", value: name }],
      };
      // upload metadata
      const metaDataFile = await ipfsClient.add(JSON.stringify(metadata));
      const metadataURL = `${process.env.REACT_APP_IPFS_GATEWAY}${metaDataFile.path}`;

      setURL(metadataURL);
      return metaDataFile.path;
    } catch (err) {
      setIsWaiting(false);
      setMessage("Error in uploading image to IPFS..");
    }
  };

  const mintImage = async (tokenURI) => {
    try {
      setMessage("Waiting for Mint...");

      const signer = await provider.getSigner();
      const transaction = await nft
        .connect(signer)
        .mint(tokenURI, { value: ethers.utils.parseUnits(".005", "ether") });
      await transaction.wait();
      setIsWaiting(false);
      setMessage("Image minted successfully. Check your opensea account");
      setImageData("");
    } catch (err) {
      setIsWaiting(false);
      setMessage("Minting failed please check if wallet connected..");
    }
  };

  useEffect(() => {
    loadBlockchainData();
  }, []);

  return (
    <div>
      <Navigation account={account} setAccount={setAccount} />

      <div className="form">
        <form onSubmit={submitHandler}>
          <input
            type="text"
            placeholder="Create a name..."
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <textarea
            placeholder="Create a description..."
            className="testarea"
            onChange={(e) => setDescription(e.target.value)}
            rows={10}
          />
          <br /> <br />
          <input
            type="button"
            onClick={createImage}
            value="Create Image"
            disabled={isWaiting}
          />
          <input type="submit" value="Mint" disabled={isWaiting} />
        </form>

        <div className="image">
          {image ? <img src={image} alt="Generate Image with AI" /> : <></>}
        </div>
        {isWaiting ? (
          <div className="image__placeholder">
            <Spinner animation="border" />
            <p>{message}</p>
          </div>
        ) : (
          <div className="image__placeholder">
            <p>{message}</p>
          </div>
        )}
      </div>
      <p>
        Forked from :&nbsp;
        <a
          href="https://github.com/dappuniversity/ai_nft_generator/tree/master/src"
          target="_blank"
          rel="noreferrer"
        >Dapp University</a>
      </p>
      <p>Cost for minting is .005 eth</p>
      <p>
        &nbsp;
        <a
          href={collectionUrl}
          target="_blank"
          rel="noreferrer"
        >View Collection</a>
      </p>
      {!isWaiting && url && (
        <p>
          View&nbsp;
          <a href={url} target="_blank" rel="noreferrer">
            Metadata
          </a>
        </p>
      )}
    </div>
  );
}

export default App;
